import * as React from "react"

import Layout from '../../components/layout/Layout';
import ContentAM from '../../components/ContentAM';
import license from '../../data/corsi-patente/patente-AM/license.json';
import PageNavigator from "../../components/layout/PageNavigator";
import Seo from '../../components/layout/SEO';
import ContentPatenteAM from '../../components/corsi-patente/ContentPatenteAM';
import BannerCompass from '../../components/BannerCompass';

const PatenteAM = () => {
	return (
		<Layout>
            <Seo title=""
            description={""}
            />
			<PageNavigator link="/corsi-patente" text="Corsi patenti" />
			{/* <ContentAM license={license} /> */}
            <ContentPatenteAM/>
			<BannerCompass/>
			
		</Layout>
	)
};

export default PatenteAM;