import React from "react";
import Motorino from "../../../static/vehicles/kymco2.jpg";

const ContentPatenteAM = () => {
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-8 sm:px-5 py-12 pb-56 mx-auto flex flex-col">
        <div className="lg:w-full mx-auto">
          <h1 className="text-center sm:text-6xl text-4xl font-roboto font-bold tracking-tight text-gray-900">
            Corso per patente AM{" "}
          </h1>
          <h2 className="lg:w-2/3 mx-auto mt-4 text-center sm:text-2xl text-xl font-sans font-normal tracking-tight text-gray-900">
            Con la patente AM, anche detta patentino, potrai guidare ciclomotori
            di cilindrata fino a 50cc dai 14 anni in su.
          </h2>
          <div className="flex flex-col sm:flex-row mt-4 sm:mt-10">
            <div className="sm:w-full sm:pl-8 sm:py-8 mt-4 pt-4 sm:mt-0 text-left sm:text-left">
              <div className="sm:mb-4 mb-4 px-8 py-2">
                <h2 className="text-center text-2xl font-roboto font-bold tracking-tight text-gray-900">
                  Mezzo utilizzato durante il corso
                </h2>
                <p className="text-center font-sans text-xl tracking-tight text-gray-900">
                  Kymco Agility 50
                </p>
              </div>

              <div className="sm:w-full text-center sm:py-8 py-8">
                {/* Image */}
                <div className="w-84 h-64 rounded-lg inline-flex items-center justify-center bg-gray-200 text-gray-400">
                  <img
                    alt="Mezzo utilizzato per conseguire la patente AM"
                    className="w-full h-full rounded-xl block inset-0"
                    src={Motorino}
                  />
                </div>
                {/* Description */}
                <div className="flex flex-col items-center text-center justify-center bg-blue-100 rounded shadow-lg sm:m-12 m-2">
                  <p className="sm:p-12 p-4 title-font font-roboto font-bold tracking-tight text-gray-900 text-xl text-start leading-10">
                    La AM è la patente perfetta per chi desidera essere autonomo
                    per brevi spostamenti o per chi si muove in città, dove il
                    traffico rallenta le auto e parcheggiare è più semplice con
                    un ciclomotore. <br />
                    Il patentino del motorino è prediletto anche
                    dai ragazzi, poichè possono conseguirlo già dai 14 anni. <br />
                    Attenzione però, per trasportare con sè un passeggero il
                    conducente deve avere minimo 16 anni compiuti. <br />
                    Il corso per la AM si compone di una parte teorica e pratica. <br />
                     La teoria si svolge nella nostra autoscuola a Pisa. Una volta superato
                    il quiz, ti verrà consegnato il foglio rosa, valido sei
                    mesi, con cui potrai fare pratica anche presso la nostra
                    autoscuola Faro. 
                    <br />La prova pratica invece è composta da una
                    prima fase, teorica, una seconda fase di abilità presso la
                    nostra pista per moto, ed una terza fase che consiste nel
                    giro su strada. Una volta superate tutte le prove la patente
                    sarà tua!
                  </p>
                </div>
              </div>
            </div>
            <div className="order-first sm:order-last sm:w-full sm:pl-8 sm:py-8 sm:border-l sm:border-gray-400 sm:border-t-0 border-t border-gray-400 mt-4 pt-4 sm:mt-0 text-left sm:text-left">
              <h2 className="text-3xl title-font mb-4 mt-4 font-roboto font-bold tracking-tight text-gray-900">
                Età minima:
              </h2>
              <p className="leading-relaxed text-lg mb-4 font-roboto font-bold tracking-tight text-gray-900">
                14 anni
              </p>

              <h2 className="text-3xl title-font mb-4 font-roboto font-bold tracking-tight text-gray-900">
                Abilitazione guida dei seguenti veicoli e motoveicoli:
              </h2>
              <ul className="list-disc sm:ml-8 mt-4 ml-4">
                <li className="leading-relaxed text-xl mb-4 font-roboto font-bold tracking-tight text-gray-900">
                  Ciclomotori a due ruote di categoria L1e e veicoli a tre ruote
                  di categoria L2e con velocità massima di costruzione non
                  superiore a 45 km/h, la cui cilindrata è inferiore o uguale a
                  50 cm³ se a combustione interna, oppure la cui potenza
                  nominale continua massima è inferiore o uguale a 4 kW per i
                  motori elettrici.
                </li>
                <li className="leading-relaxed text-xl mb-4 font-roboto font-bold tracking-tight text-gray-900">
                  Quadricicli leggeri di categoria L6e aventi le seguenti
                  caratteristiche:
                  <ul className="list-disc sm:ml-8">
                    <li className="leading-relaxed text-xl mb-4 font-roboto font-bold tracking-tight text-gray-900">
                      Massa a vuoto inferiore o pari a 350 kg (batterie escluse
                      per i veicoli elettrici)
                    </li>
                    <li className="leading-relaxed text-xl mb-4 font-roboto font-bold tracking-tight text-gray-900">
                      Velocità massima di costruzione inferiore o uguale a 45
                      km/h
                    </li>
                    <li className="leading-relaxed text-xl mb-4 font-roboto font-bold tracking-tight text-gray-900">
                      Cilindrata inferiore o pari a 50 cm³ per i motori ad
                      accensione comandata; oppure potenza massima netta
                      inferiore o uguale a 4 kW per motori a combustione interna
                      o altri motori; oppure potenza nominale continua massima
                      inferiore o uguale a 4 kW per i motori elettrici.
                    </li>
                  </ul>
                </li>
              </ul>

              <h2 className="text-3xl title-font mt-16 font-roboto font-bold tracking-tight text-gray-900">
                Documenti necessari:
              </h2>
              <ul className="list-disc sm:ml-8 mt-4 ml-4">
                <li className="leading-relaxed text-xl mb-4 font-roboto font-bold tracking-tight text-gray-900">
                  Cartà d'identità candidato / Carta d'identità genitore o
                  tutore legale
                </li>
                <li className="leading-relaxed text-xl mb-4 font-roboto font-bold tracking-tight text-gray-900">
                  Permesso di soggiorno (solo per cittadini extra UE)
                </li>
                <li className="leading-relaxed text-xl mb-4 font-roboto font-bold tracking-tight text-gray-900">
                  2 fototessere
                </li>
                <li className="leading-relaxed text-xl mb-4 font-roboto font-bold tracking-tight text-gray-900">
                  Certificato anamnestico (rilasciato dal medico curante)
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContentPatenteAM;
